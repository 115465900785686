import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';

import ManualOnNL from '../../../../../../assets/images/manual-on-nl.svg';
import ManualOnFR from '../../../../../../assets/images/manual-on-fr.svg';
import ManualOffNL from '../../../../../../assets/images/manual-off-nl.svg';
import ManualOffFR from '../../../../../../assets/images/manual-off-fr.svg';

const getSource = (language, isEnabled) => {
  if (language === 'fr') return isEnabled ? ManualOnFR : ManualOffFR;
  return isEnabled ? ManualOnNL : ManualOffNL;
};

export const ManualButton = ({ isEnabled, onClick }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const src = getSource(language, isEnabled);
  const tooltip = isEnabled ? 'dock.buttons.manualOff.tooltip' : 'dock.buttons.manualOn.tooltip';

  return (
    <button type="button" onClick={onClick} title={t(tooltip)}>
      <img data-testid="manual-icon" src={src} alt={`icon ${t(tooltip)}`} />
    </button>
  );
};

ManualButton.propTypes = {
  isEnabled: bool.isRequired,
  onClick: func.isRequired,
};
