import React, { useRef, useEffect, useContext, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { shape, string, number, object, bool, func, array, arrayOf } from 'prop-types';
import axios from 'axios';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';

import withDimensions from '../../../../hocs/with-dimensions';
import FabricService from '../../services/fabric-service';
import { getCurrentTool, getTools } from '../../../../selectors/tools';
import { setCurrentTool, setColor, setBackgroundColor, setSize } from '../../../../actions/tools';
import { disableZoomToFitMode, setZoomLevel } from '../../../../actions/navigation';
import { getZoomLevel, getZoomToFitMode } from '../../../../selectors/navigation';
import { UserSettingsContext } from '../../context/user-settings-context';
import Tools from '../../../../enums/tools';

import { setPlayerMode } from '../../../../actions/playerMode';
import playerMode from '../../../../enums/playerMode';
import MathTools from '../book/math-tools/MathTools';

/**
 * This should never change. Is used for switching between single and dual page mode in the book mode.
 */
const PAGES_RENDERED = [];

function useCanvasZoom(fabricService, zoomLevel, zoomToFitMode, canvasWidth, canvasHeight) {
  useEffect(() => {
    if (zoomToFitMode) fabricService.current.scaleCanvasToFit(fabricService.current.zoomLevel);
  }, [fabricService, zoomToFitMode]);

  useEffect(() => {
    fabricService.current.handleCanvasResize({ width: canvasWidth, height: canvasHeight });
  }, [fabricService, canvasWidth, canvasHeight]);

  useEffect(() => {
    fabricService.current.setZoom(zoomLevel);
  }, [fabricService, zoomLevel]);
}

function useCanvasSidebarPositioning(fabricService, openDrawer, zoomLevel, zoomToFitMode, updateViewPort) {
  useEffect(() => {
    fabricService.current.setDrawerOpenSide(openDrawer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (zoomLevel === 1 && zoomToFitMode)
      fabricService.current.shiftViewportForDrawer(openDrawer, () => {
        updateViewPort();
        fabricService.current.fabricCanvas.requestRenderAll();
      });
  }, [fabricService, openDrawer, zoomLevel, zoomToFitMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fabricService.current.setDrawerOpenSide(openDrawer);
  }, [fabricService, openDrawer]);
}

function Whitepage(props) {
  const {
    whitepage,
    currentTool,
    dimensions: { width, height },
    tools,
    zoomToFitMode,
    zoomLevel,
    paper,
    initialDrawings,
    initialAnnotations,
    dispatch,
  } = props;

  const { sidebarAnchor, isSidebarOpen } = useContext(UserSettingsContext);
  const fabricService = useRef(null);
  const drawingPaths = useRef([]);
  const currentAnnotations = useRef([]);
  const selectedAnnotation = useRef(null);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState(undefined);
  const [viewportTransform, setViewportTransform] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledPersist = useCallback(
    throttle(() => {
      return axios.put(whitepage.url, drawingPaths.current, {
        headers: {
          'Content-Type': 'application/json',
          'x-amz-acl': 'bucket-owner-full-control',
        },
      });
    }, 3000),
    [whitepage.url, whitepage.id, dispatch],
  );

  const [t] = useTranslation();

  const zoomSelectionHandler = useCallback(
    nextZoom => {
      dispatch(setZoomLevel(nextZoom));
      dispatch(setCurrentTool(Tools.POINTER));
    },
    [dispatch],
  );

  const selectionEraseHandler = useCallback(
    rect => {
      drawingPaths.current.push(rect.toJSON());
      fabricService.current.markingsGroup.addWithUpdate(rect);
      throttledPersist();
    },
    [throttledPersist],
  );

  const disableZoomToFit = useCallback(() => dispatch(disableZoomToFitMode()), [dispatch]);

  const openDrawer = isSidebarOpen ? sidebarAnchor : undefined;

  useEffect(() => {
    fabricService.current = new FabricService('white-page-canvas', undefined, undefined, playerMode.WHITEPAGE);
    fabricService.current.initialize(2480, 3508);

    return () => {
      fabricService.current.dispose();
    };
  }, [dispatch]);

  useEffect(() => {
    fabricService.current.addFreeDrawingListeners(path => {
      drawingPaths.current.push(path.toJSON());
    }, throttledPersist);

    return () => {
      fabricService.current.removeFreeDrawingListeners();
    };
  }, [throttledPersist]);

  useEffect(() => {
    /**
     * Clear current when whitepage id changes.
     */
    currentAnnotations.current = [];
    selectedAnnotation.current = undefined;
  }, [whitepage.id]);

  useEffect(() => {
    setSelectedAnnotationId(undefined);
  }, [currentTool]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveAnnotations = useCallback(
    throttle(() => {
      // do not store annotations without text
      const annotationsToSave = currentAnnotations.current.filter(x => x.text);

      return axios.put(whitepage.annotationsUrl, annotationsToSave, {
        headers: {
          'Content-Type': 'application/json',
          'x-amz-acl': 'bucket-owner-full-control',
        },
      });
    }, 3000),
    [whitepage.id, whitepage.annotationsUrl, dispatch],
  );

  useEffect(() => {
    fabricService.current.addClickListener(ann => {
      const annotation = {
        ...ann,
        text: '',
        ...tools.annotation,
        fontSize: tools.annotation.size,
      };

      currentAnnotations.current.push(annotation);
      setSelectedAnnotationId(annotation.id);
    }, setSelectedAnnotationId);

    // If we do not remove, we add multiple annotations on click.
    // We cannot simulate this situation in spec :-(
    return () => {
      fabricService.current.removeClickListener();
    };
  }, [tools]);

  useEffect(() => {
    if (currentTool === Tools.ANNOTATION && selectedAnnotationId && selectedAnnotationId === (selectedAnnotation.current || {}).id) {
      selectedAnnotation.current.fontSize = tools.annotation.size;
      selectedAnnotation.current.color = tools.annotation.color;
      selectedAnnotation.current.backgroundColor = tools.annotation.backgroundColor;

      saveAnnotations();
    }
  }, [tools, selectedAnnotationId, saveAnnotations, currentTool]);

  useEffect(() => {
    if (currentTool === Tools.ANNOTATION && selectedAnnotationId) {
      const current = currentAnnotations.current.find(x => x.id === selectedAnnotationId);
      dispatch(setColor(current.color));
      dispatch(setSize(current.fontSize));
      dispatch(setBackgroundColor(current.backgroundColor));
    }
  }, [selectedAnnotationId, dispatch, currentTool]);

  useEffect(() => {
    const index = currentAnnotations.current.findIndex(x => x.id === selectedAnnotationId);
    const currentlySelected = currentAnnotations.current[index];
    selectedAnnotation.current = currentlySelected;

    // move current to top.
    if (currentlySelected) currentAnnotations.current = currentAnnotations.current.filter((x, i) => i !== index).concat(currentlySelected);
  }, [selectedAnnotationId]);

  useEffect(() => {
    // remove all empty annotations except for the currently selected
    currentAnnotations.current = currentAnnotations.current.filter(x => x.text || x.id === selectedAnnotationId);
  }, [selectedAnnotationId, currentTool]);

  useEffect(() => () => saveAnnotations.flush(), [saveAnnotations]);

  useEffect(() => () => throttledPersist.flush(), [throttledPersist]);

  const updateViewport = useCallback(() => {
    const fabricVpt = fabricService.current.getViewportTransform();

    if (!viewportTransform || viewportTransform.some((item, i) => item !== fabricVpt[i])) {
      setViewportTransform([...fabricVpt]);
    }
  }, [viewportTransform]);

  useEffect(() => {
    fabricService.current.addDragListeners(
      {
        [Tools.ZOOM_SELECT]: zoomSelectionHandler,
        [Tools.SELECTION_ERASER]: selectionEraseHandler,
      },
      updateViewport,
      () => zoomToFitMode && disableZoomToFit(),
    );

    return () => {
      fabricService.current.removeDragListeners();
    };
  }, [zoomSelectionHandler, selectionEraseHandler, disableZoomToFit, zoomToFitMode, updateViewport]);

  useEffect(() => {
    function pinchHandler(zoomFactor) {
      dispatch(setZoomLevel(zoomFactor));
    }
    fabricService.current.addPinchListeners(pinchHandler);
  }, [dispatch]);

  useEffect(() => {
    function clearWhitepage() {
      fabricService.current.clearWhitepage();
      drawingPaths.current = [];
      axios.delete(whitepage.url);

      setSelectedAnnotationId(undefined);
      currentAnnotations.current = [];
      saveAnnotations();
    }

    document.addEventListener('erase-all-clicked', clearWhitepage);

    return () => {
      document.removeEventListener('erase-all-clicked', clearWhitepage);
    };
  }, [whitepage.url, saveAnnotations]);

  useEffect(() => {
    fabricService.current.renderBookPage(paper, 'left', 'left');
  }, [paper]);

  useEffect(() => {
    drawingPaths.current = [...initialDrawings];
  }, [initialDrawings]);

  useEffect(() => {
    currentAnnotations.current = [...initialAnnotations];
  }, [initialAnnotations]);

  useEffect(() => {
    fabricService.current.renderAnnotations(currentAnnotations.current, selectedAnnotationId, t('annotationTool.placeHolder'), true, false, saveAnnotations, selectedAnnotation);
    fabricService.current.showMarkings(drawingPaths.current, true, false);
  }, [initialDrawings, initialAnnotations, saveAnnotations, selectedAnnotationId, t, tools]);

  useEffect(() => {
    fabricService.current.addWhitepageHeader(whitepage.title, () => dispatch(setPlayerMode(playerMode.BOOK)), t('whitepages.modal.buttons.close'));
  }, [dispatch, whitepage.title, t]);

  useCanvasZoom(fabricService, zoomLevel, zoomToFitMode, width, height);

  useCanvasSidebarPositioning(fabricService, openDrawer, zoomLevel, zoomToFitMode, updateViewport);

  useEffect(() => {
    if (fabricService.current) {
      fabricService.current.renderAll();
      updateViewport();
    }
  });

  useEffect(() => {
    fabricService.current.setCurrentTool(currentTool, { ...tools[currentTool] });
  }, [currentTool, tools]);

  function saveArc(position) {
    const circle = fabricService.current.getTempCircleToPersist();

    circle.set(position);
    drawingPaths.current.push(circle.toJSON());

    fabricService.current.markingsGroup.addWithUpdate(circle);

    throttledPersist();
  }

  const setFreeDrawingStrategy = useCallback(strategy => {
    fabricService.current.fabricCanvas.freeDrawingBrush.freeDrawingStrategy = strategy;
  }, []);

  return (
    <div className="canvas-wrapper">
      <canvas id="white-page-canvas" data-testid="white-page-canvas" />
      {viewportTransform && (
        <MathTools
          bookDimensions={{ width: paper.width, height: paper.height }}
          pagesRendered={PAGES_RENDERED}
          viewPortTransform={viewportTransform}
          viewMode="whitepage"
          onDraftingCompassTempDraw={opts => {
            fabricService.current.renderTempCircle(opts);
          }}
          onDraftingCompassFinishedDrawing={saveArc}
          setFreeDrawingStrategy={setFreeDrawingStrategy}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  currentTool: getCurrentTool(state),
  tools: getTools(state),
  zoomToFitMode: getZoomToFitMode(state),
  zoomLevel: getZoomLevel(state),
});

Whitepage.propTypes = {
  whitepage: shape({
    id: string.isRequired,
    url: string.isRequired,
    annotationsUrl: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  currentTool: string.isRequired,
  dimensions: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  tools: object.isRequired,
  zoomToFitMode: bool.isRequired,
  zoomLevel: number.isRequired,
  dispatch: func.isRequired,
  paper: object.isRequired,
  initialDrawings: array,
  initialAnnotations: arrayOf(
    shape({
      id: string.isRequired,
      text: string.isRequired,
      top: number.isRequired,
      left: number.isRequired,
    }),
  ),
};

Whitepage.defaultProps = {
  initialDrawings: undefined,
  initialAnnotations: undefined,
};

export const ConnectedWhitepage = connect(mapStateToProps)(Whitepage);

export default withDimensions(ConnectedWhitepage);
