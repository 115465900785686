import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { string, func, bool, number, shape, oneOf, object } from 'prop-types';
import { connect } from 'react-redux';

import api from '../../services/api';

import SidebarToggle from './components/sidebar/toggle';
import Sidebar from './components/sidebar';
import Dock from './components/dock';
import BookContainer from './components/book';
import Whitepage from './components/whitepages';
import Dialogs from './components/dialogs/dialogs';
import PlayerTitle from './context/PlayerTitle';
import PopUpManual from './components/popUpManual/PopUpManual';

import { UserSettingsProvider } from './context/user-settings-context';
import { initialize } from '../../actions/player';
import { openWhitepage } from '../../actions/whitepages';
import { getDigibookForId } from '../../selectors/digibooks';
import { FEATURE_TOGGLES_RETRIEVED } from '../../actions/actionNames';

import { getIsReady, getPlayerMode, getCurrentWhitepage, getInitialToolbarPosition } from '../../selectors/player';
import { getWhitepageById } from '../../selectors/whitepages';

import PlayerMode from '../../enums/playerMode';
import MaterialSorting from '../../enums/materialSorting';
import { ImageCacheProvider } from './context/image-cache-context';
import { TextCacheProvider } from './context/text-cache-context';
import PageEventContextProvider from './context/PageEventContext';
import { toggleLoadingScreen } from '../../utils/loadingScreen';
import { getModuleById } from '../../selectors/module';
import { onStoreError } from '../../store';
import { STUDIO_GROUP } from '../../constants/constants';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import GoogleAnalyticsContext from '../../contexts/GoogleAnalyticsContext';
import useUrlPageUpdate from './use-url-page-update';

export function Player(props) {
  const {
    digibookId,
    superModuleId,
    initialPage,
    digibook,
    isReady,
    dispatch,
    whitepage,
    playerMode,
    initialOpenDrawer,
    initialToolbarPosition,
    initialOpenWhitepageId,
    initialWhitepageObject,
    bookIsRendered,
    whitepageIsRendered,
    featureToggles,
    module,
  } = props;

  const [initialWhitepageShown, setInitialWhitepageShown] = useState(false);

  const gaContextValue = useGoogleAnalytics();
  useUrlPageUpdate();

  useEffect(() => {
    async function getToggles() {
      try {
        const { data } = await api.get('/feature-toggles', {
          skipAuthorization: true,
          params: {
            group: STUDIO_GROUP,
          },
        });

        const newToggles = data.reduce(
          (toggles, currentToggle) => ({
            ...toggles,
            [currentToggle.name]: currentToggle.enabled,
          }),
          {},
        );

        dispatch({ type: FEATURE_TOGGLES_RETRIEVED, payload: newToggles });
      } catch (e) {
        onStoreError(e)(dispatch);
      }
    }

    getToggles();
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(featureToggles).length > 0) dispatch(initialize(digibookId, superModuleId, initialPage));
  }, [dispatch, digibookId, superModuleId, initialPage, featureToggles]);

  useEffect(() => {
    if (isReady && !initialWhitepageShown && initialWhitepageObject) {
      dispatch(openWhitepage(initialWhitepageObject.id));
      setInitialWhitepageShown(true);
    }
  }, [isReady, initialWhitepageShown, initialWhitepageObject, dispatch]);

  useEffect(() => {
    if (!initialOpenWhitepageId && bookIsRendered) {
      toggleLoadingScreen();
    } else if (initialOpenWhitepageId && whitepageIsRendered) {
      toggleLoadingScreen();
    }
  }, [bookIsRendered, whitepageIsRendered, initialOpenWhitepageId]);

  return isReady ? (
    <UserSettingsProvider initialToolbarPosition={initialToolbarPosition} initialOpenDrawer={initialOpenDrawer} module={module}>
      <PageEventContextProvider>
        <GoogleAnalyticsContext.Provider value={gaContextValue}>
          <PlayerTitle superModuleId={superModuleId} />
          <Sidebar digibook={digibook} superModuleId={superModuleId} />
          <ImageCacheProvider>
            <TextCacheProvider>
              {digibook && playerMode === PlayerMode.BOOK && (
                <div className={classNames('pbb-book', 'full-height')}>
                  <BookContainer digibook={digibook} key={digibookId} initialPage={initialPage} />
                </div>
              )}
            </TextCacheProvider>
          </ImageCacheProvider>
          {whitepage && playerMode === PlayerMode.WHITEPAGE && <Whitepage whitepage={whitepage} />}
          <SidebarToggle anchor="left" />
          <SidebarToggle anchor="right" />
          <Dialogs superModuleId={superModuleId} />
          <PopUpManual />
          <Dock digibook={digibook} />
        </GoogleAnalyticsContext.Provider>
      </PageEventContextProvider>
    </UserSettingsProvider>
  ) : null;
}

Player.propTypes = {
  // Own Props
  digibookId: string.isRequired,
  initialPage: number,
  superModuleId: string,
  initialOpenDrawer: string,
  initialOpenWhitepageId: string,

  // Connected Props
  playerMode: oneOf(Object.values(PlayerMode)).isRequired,
  isReady: bool,
  digibook: shape({
    id: string.isRequired,
    module: string.isRequired,
  }),
  initialToolbarPosition: string,
  dispatch: func.isRequired,
  whitepage: shape({
    url: string.isRequired,
  }),
  initialWhitepageObject: shape({ url: string }),
  bookIsRendered: bool,
  whitepageIsRendered: bool,
  featureToggles: object,
  module: shape({
    id: string.isRequired,
    defaultSorting: oneOf(Object.values(MaterialSorting)),
  }),
};

Player.defaultProps = {
  isReady: false,
  initialPage: 0,
  superModuleId: undefined,
  digibook: undefined,
  whitepage: undefined,
  initialOpenDrawer: undefined,
  initialToolbarPosition: undefined,
  initialWhitepageObject: undefined,
  initialOpenWhitepageId: undefined,
  bookIsRendered: false,
  whitepageIsRendered: false,
  featureToggles: undefined,
  module: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const digibook = getDigibookForId(state, ownProps.digibookId);

  return {
    isReady: getIsReady(state),
    initialToolbarPosition: getInitialToolbarPosition(state),
    digibook,
    playerMode: getPlayerMode(state),
    whitepage: getCurrentWhitepage(state),
    initialWhitepageObject: getWhitepageById(state, ownProps.initialOpenWhitepageId),
    bookIsRendered: state.player.isRendered,
    whitepageIsRendered: state.player.isWhitepageRendered,
    featureToggles: state.featureToggles,
    module: digibook ? getModuleById(state, digibook.module) : undefined,
  };
};

export default connect(mapStateToProps)(Player);
