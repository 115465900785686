import React from 'react';
import classNames from 'classnames';
import { string, shape, bool } from 'prop-types';
import { connect } from 'react-redux';

import SlidingButton from './items/slidingButton';
import DrawerButtonsSidebarItem from './items/drawerButtons';
import NavigationSidebarItem from './items/navigation';
import ToolsSidebarItem from './items/tools';

import Drawer from './drawer';

import { getTeacherFeaturesEnabledFor } from '../../../../selectors/digibooks';
import { UserSettingsConsumer } from '../../context/user-settings-context';

const panelRoot = 'pbb-panel';

export function Sidebar(props) {
  const { superModuleId, bookIsRendered, digibook, teacherFeaturesEnabled } = props;

  return (
    <UserSettingsConsumer>
      {({ sidebarAnchor, activeDrawer, setActiveDrawer, isSidebarOpen, setSidebarOpened, showMarkings, setShowMarkings }) => (
        <div className={classNames(panelRoot, `${panelRoot}--${sidebarAnchor}`)}>
          <div className="pbb-sidebar">
            {bookIsRendered && <SlidingButton superModuleId={superModuleId} slideOutDelay={500} />}
            <div className="pbb-sidebar__divider" />
            <ToolsSidebarItem showMarkings={showMarkings} setShowMarkings={setShowMarkings} />
            <div className="pbb-sidebar__divider" />
            <div className="pbb-sidebar__spacer" />
            <DrawerButtonsSidebarItem
              onClick={active => {
                if (active !== activeDrawer) {
                  setActiveDrawer(active);
                  if (!isSidebarOpen) setSidebarOpened(true);
                } else {
                  setSidebarOpened(!isSidebarOpen);
                }
              }}
              isOpen={isSidebarOpen}
              activeDrawer={activeDrawer}
            />
            <NavigationSidebarItem />
            <div className="pbb-sidebar__divider" />
            <div className="pbb-sidebar__spacer" />
          </div>
          <Drawer isOpen={isSidebarOpen} activeDrawer={activeDrawer} superModuleId={superModuleId} digibook={digibook} teacherFeaturesEnabled={teacherFeaturesEnabled} />
        </div>
      )}
    </UserSettingsConsumer>
  );
}

Sidebar.propTypes = {
  // Own Props
  superModuleId: string,
  digibook: shape({
    id: string.isRequired,
    module: string.isRequired,
  }).isRequired,
  // Connected Props
  bookIsRendered: bool,
  teacherFeaturesEnabled: bool,
};

Sidebar.defaultProps = {
  superModuleId: undefined,
  bookIsRendered: false,
  teacherFeaturesEnabled: false,
};

const mapStateToProps = state => ({
  bookIsRendered: state.player.isRendered,
  teacherFeaturesEnabled: getTeacherFeaturesEnabledFor(state),
});

export default connect(mapStateToProps)(Sidebar);
