import { number, shape, string } from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OptionsMenu } from '@pelckmans/business-components/components/options-menu';
import SlideSetIcon from '@pelckmans/business-components/icons/SlideSet';
import SlideSetUserIcon from '@pelckmans/business-components/icons/SlideSetUser';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import OptionsMenuActions from './OptionsMenuActions';
import AnchorPosition from '../../../../../../enums/anchorposition';
import { UserSettingsContext } from '../../../../context/user-settings-context';

function ReviewBadge() {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-label--alert pbb-list__label" data-testid="review-label">
      <span>{t('drawer.slideSets.states.review')}</span>
    </div>
  );
}

export default function SlideSetListItem({ slideSet, nodeId }) {
  const { id, name, state } = slideSet;

  const { t } = useTranslation();

  const analytics = useAnalytics();
  const moduleId = useSelector(getCurrentModuleId);

  const { sidebarAnchor } = useContext(UserSettingsContext);

  const handleAnalytics = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: moduleId,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });
  };

  const getDirection = () => {
    if (sidebarAnchor === AnchorPosition.LEFT) return 'right';
    if (sidebarAnchor === AnchorPosition.RIGHT) return 'left';

    return 'right';
  };

  return (
    <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
      <div className="pbb-list__link-wrap">
        <a
          onClick={handleAnalytics}
          className="pbb-list__link pbb-flex-wrap"
          title={name}
          href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="pbb-flex">
            {slideSet.userId ? <SlideSetUserIcon className="pbb-list__link-icon" /> : <SlideSetIcon className="pbb-list__link-icon" />}
            <span className="pbb-list__link-text">{name}</span>
          </div>
          {state === 'REVIEW' && (
            <div className="pbb-list__labels">
              <ReviewBadge />
            </div>
          )}
        </a>
        <div className="pbb-list__actions">
          <OptionsMenu title={t('buttons.shareButton.tooltip')} direction={getDirection()} boundingBoxPadding="10" viewScroll="close">
            <OptionsMenuActions slideSet={slideSet} nodeId={nodeId} />
          </OptionsMenu>
        </div>
      </div>
    </li>
  );
}

SlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
  nodeId: string.isRequired,
};
