import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';

export default function FavoriteButton({ isActive, onClick, className }) {
  const [t] = useTranslation();
  return (
    <button
      type="button"
      className={classNames('pbb-favorite', { 'pbb-favorite--active': isActive }, className)}
      onClick={onClick}
      data-testid="btn-favorite"
      title={isActive ? t('components.favoriteButton.active.tooltip') : t('components.favoriteButton.inactive.tooltip')}
    >
      <i className="icon-favorite" />
      <i className="icon-favorite-full" />
    </button>
  );
}

FavoriteButton.propTypes = {
  isActive: bool,
  onClick: func.isRequired,
  className: string,
};

FavoriteButton.defaultProps = {
  isActive: false,
  className: undefined,
};
