import get from 'get-value';
import { createSelector } from 'reselect';
import { securedFilesEntitiesSelector } from './files';
import { getModuleById } from './module';
import { identity } from '../utils/identity';

export const currentDigibookIdSelector = state => get(state, ['player', 'digibookId']);
export const digibooksEntitiesSelector = state => get(state, ['player', 'entities', 'digibooks']);
export const getCurrentDigibook = state => get(state, ['player', 'entities', 'digibooks', currentDigibookIdSelector(state)]);
export const getTotalPagesForDigibook = state => get(getCurrentDigibook(state), ['totalPages']);
export const getSecuredFileLinkFor = (state, securedFileId) => get(securedFilesEntitiesSelector(state), [securedFileId, 'href']);
export const getCurrentModuleId = state => get(getCurrentDigibook(state), 'module');
export const getDigibookModuleInfoById = (state, id) => get(state, ['player', 'entities', 'modules', id]);
export const getCurrentModule = state => get(state, ['player', 'entities', 'modules', getCurrentModuleId(state)]);
export const getTeacherFeaturesEnabledFor = state => get(getCurrentDigibook(state), 'teacherFeaturesEnabled');
export const getDigibookForId = (state, id) => get(state, ['player', 'entities', 'digibooks', id]);
export const getCurrentSuperModuleId = state => get(state, ['player', 'superModuleId']);

export const getSecuredFileForCurrentDigibook = (state, fileField) => {
  const id = currentDigibookIdSelector(state);
  const secureFileId = get(state, ['player', 'entities', 'digibooks', id, fileField]);
  return get(state, ['player', 'entities', 'securedFiles', secureFileId]);
};

export const getRelatedDigibookDataForCurrentDigibook = state => get(state, ['player', 'entities', 'relatedDigibooks', getCurrentModuleId(state)]);

export const getRelatedDigibookAppsForCurrentDigibook = createSelector([getRelatedDigibookDataForCurrentDigibook, getCurrentSuperModuleId], (digibookApps, superModuleId) =>
  digibookApps
    .map(app => ({
      superModuleId,
      digibookId: (app.linkedApp || {}).id,
      niceName: app.module.niceName,
      bookType: app.module.bookType,
      cover: app.module.cover,
      primaryColor: app.superModule.primaryColor,
      secundaryColor: app.superModule.secundaryColor,
      isLicensed: !!app.linkedApp,
    }))
    .filter(identity),
);

export const getSuperModuleForDigibook = state => {
  const superModuleId = getCurrentSuperModuleId(state);
  return getModuleById(state, superModuleId);
};

export const getModuleForDigibook = state => {
  const moduleId = getCurrentModuleId(state);
  return getModuleById(state, moduleId);
};
