import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export default function ShareButton(props) {
  const { onClick, className } = props;
  const [t] = useTranslation();
  return (
    <button type="button" className={classNames('pbb-share', className)} onClick={onClick} data-testid="btn-share" title={t('buttons.shareButton.tooltip')}>
      <i className="icon-share" />
    </button>
  );
}

ShareButton.propTypes = {
  className: string,
  onClick: func.isRequired,
};

ShareButton.defaultProps = {
  className: undefined,
};
