import React, { useContext, useEffect, useRef, useState } from 'react';
import { number, string, shape } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Book from './book';

import withDimensions from '../../../../hocs/with-dimensions';

import { gotoNextPage, gotoPreviousPage } from '../../../../actions/navigation';

import { getPageNumbersToShow, getAnswerLayerPagesToShow, getCurrentSpread, getVisiblePages } from '../../../../selectors/rendering';
import { getCurrentDigibook } from '../../../../selectors/digibooks';

import { UserSettingsConsumer } from '../../context/user-settings-context';

import usePageProvider from './use-page-provider';
import useVisibleAnnotations from './useAnnotations';
import useVisibleLinkAreas from './use-link-areas-for-visible-pages';
import useVisibleAnswerSets from './use-answer-sets-for-visible-pages';
import { PageEventContext } from '../../context/PageEventContext';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../enums/analytics';
import useAnalytics from '../../../../hooks/useAnalytics';
import { getIsSolutionsPageVisible } from '../../../../selectors/player';

export function BookContainer(props) {
  const { digibook, dimensions } = props;

  const dispatch = useDispatch();

  const pageNumbersToShow = useSelector(getPageNumbersToShow);
  const spread = useSelector(getCurrentSpread);
  const visiblePages = useSelector(getVisiblePages);
  const answersToShow = useSelector(getAnswerLayerPagesToShow);
  const { manualMargins } = useSelector(getCurrentDigibook);
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);

  const analytics = useAnalytics();

  const {
    images: [bookImages, answerImages],
    text: [bookText, answerText],
    manual: manualImages,
  } = usePageProvider(digibook.id, digibook.systemToken, digibook.totalPages, pageNumbersToShow, answersToShow);

  const [annotationsForSpread, addAnnotation, setSelectedAnnotationId, selectedAnnotationId, saveAnnotations, currentlyEditing] = useVisibleAnnotations(
    digibook,
    spread,
    visiblePages,
  );

  const visibleLinkAreas = useVisibleLinkAreas(digibook, visiblePages);
  const visibleAnswerSets = useVisibleAnswerSets(digibook, visiblePages);

  const { capturePageEvent } = useContext(PageEventContext);

  // Capture an event on initial load without subLocationId
  useEffect(() => {
    capturePageEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function keyNavigationHandler(ev) {
      if (selectedAnnotationId || document.querySelectorAll('.ngdialog-overlay').length !== 0) {
        return;
      }

      switch (ev.key) {
        case 'Up':
        case 'ArrowUp':
        case 'Left':
        case 'ArrowLeft':
        case 'PageDown':
          capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.KEYBOARD);
          dispatch(gotoPreviousPage());
          break;
        case 'Down':
        case 'ArrowDown':
        case 'Right':
        case 'ArrowRight':
        case 'PageUp':
          capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.KEYBOARD);
          dispatch(gotoNextPage());
          break;
        default:
          break;
      }
    }

    window.addEventListener('keyup', keyNavigationHandler);

    return () => window.removeEventListener('keyup', keyNavigationHandler);
  }, [capturePageEvent, dispatch, selectedAnnotationId]);

  const swipeLeft = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.GESTURES);
    dispatch(gotoNextPage());
  };

  const swipeRight = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.GESTURES);
    dispatch(gotoPreviousPage());
  };

  const captureSameDigibook = () => capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.LINKAREA);

  const captureExternalDigibook = async (objectId, superModuleId) => {
    await Promise.all([
      analytics.capture({
        eventType: ANALYTICS_EVENT_TYPES.DIGIBOOK_ACCESSED,
        objectId,
        subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.LINKAREA,
      }),
      analytics.capture({
        eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
        objectId: superModuleId,
        subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.BOOK_SWITCHER,
      }),
    ]);
  };

  const toggledSolutionsPage = useRef(false);
  const [scaleCanvasToFit, setScaleCanvasToFit] = useState(false);

  useEffect(() => {
    toggledSolutionsPage.current = true;
  }, [isSolutionsPageVisible]);

  useEffect(() => {
    if (toggledSolutionsPage.current) {
      setScaleCanvasToFit(true);

      toggledSolutionsPage.current = false;
    }
  }, [answerImages]);

  useEffect(() => {
    if (scaleCanvasToFit) {
      setScaleCanvasToFit(false);
    }
  }, [scaleCanvasToFit]);

  return (
    <UserSettingsConsumer>
      {({ sidebarAnchor, isSidebarOpen, showMarkings }) => (
        <Book
          digibookId={digibook.id}
          disableAnswerLayerBlend={digibook.disableAnswerLayerBlend}
          dimensions={dimensions}
          bookPages={bookImages}
          answerPages={answerImages}
          manualPages={manualImages}
          manualMargins={manualMargins}
          sidebarAnchor={sidebarAnchor}
          activeDrawerAnchorSide={isSidebarOpen ? sidebarAnchor : undefined}
          pageNumbersToShow={pageNumbersToShow}
          showMarkings={showMarkings}
          annotationsForSpread={showMarkings ? annotationsForSpread : undefined}
          addAnnotation={addAnnotation}
          selectedAnnotationId={selectedAnnotationId}
          setSelectedAnnotationId={setSelectedAnnotationId}
          saveAnnotations={saveAnnotations}
          visibleLinkAreas={visibleLinkAreas}
          visibleAnswerSets={visibleAnswerSets}
          selectedAnnotationRef={currentlyEditing}
          bookText={bookText}
          answerText={answerText}
          swipeLeft={swipeLeft}
          swipeRight={swipeRight}
          captureSameDigibook={captureSameDigibook}
          captureExternalDigibook={captureExternalDigibook}
          scaleCanvasToFit={scaleCanvasToFit}
        />
      )}
    </UserSettingsConsumer>
  );
}

BookContainer.propTypes = {
  digibook: shape({
    id: string.isRequired,
    systemToken: string,
  }).isRequired,
  dimensions: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withDimensions(BookContainer);
