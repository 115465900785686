import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';

import PopUpManualOnNL from '../../../../../../assets/images/pop-up-manual-on-nl.svg';
import PopUpManualOnFR from '../../../../../../assets/images/pop-up-manual-on-fr.svg';
import PopUpManualOffNL from '../../../../../../assets/images/pop-up-manual-off-nl.svg';
import PopUpManualOffFR from '../../../../../../assets/images/pop-up-manual-off-fr.svg';

const getSource = (language, isEnabled) => {
  if (language === 'fr') return isEnabled ? PopUpManualOnFR : PopUpManualOffFR;
  return isEnabled ? PopUpManualOnNL : PopUpManualOffNL;
};

export const PopUpManualButton = ({ isEnabled, onClick }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const src = getSource(language, isEnabled);
  const tooltip = isEnabled ? 'dock.buttons.manualOff.tooltip' : 'dock.buttons.manualOn.tooltip';

  return (
    <button type="button" onClick={onClick} title={t(tooltip)}>
      <img src={src} alt={`icon ${t(tooltip)}`} />
    </button>
  );
};

PopUpManualButton.propTypes = {
  isEnabled: bool.isRequired,
  onClick: func.isRequired,
};
