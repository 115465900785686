import React from 'react';
import classNames from 'classnames';

import { oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import AnchorPosition from '../../../../enums/anchorposition';
import { UserSettingsConsumer } from '../../context/user-settings-context';

export const SidebarToggle = ({ anchor }) => {
  const pbbSidebarButtonRoot = 'pbb-sidebar__switch';
  const pbbHamburgerRoot = 'pbb-hamburger';
  const isActive = currentAnchor => (currentAnchor === anchor ? 'is-active' : '');
  const [t] = useTranslation();
  return (
    <UserSettingsConsumer>
      {({ sidebarAnchor, setSidebarAnchor, isSidebarOpen, setSidebarOpened }) => (
        <div className={classNames(pbbSidebarButtonRoot, `${pbbSidebarButtonRoot}--${anchor}`)} data-testid="sidebar-toggle">
          <button
            type="button"
            className={classNames(pbbHamburgerRoot, `${pbbHamburgerRoot}--htx`, isSidebarOpen && isActive(sidebarAnchor))}
            onClick={() => {
              if (isSidebarOpen && isActive(sidebarAnchor)) {
                setSidebarOpened(false);
              }
              setSidebarAnchor(anchor);
            }}
            data-testid="sidebar-toggle-button"
            title={isSidebarOpen && isActive(sidebarAnchor) ? t('drawer.buttons.sidebarClose.tooltip') : t('drawer.buttons.sidebarToggle.tooltip')}
          >
            <span>toggle menu</span>
          </button>
        </div>
      )}
    </UserSettingsConsumer>
  );
};

SidebarToggle.propTypes = {
  anchor: oneOf(Object.values(AnchorPosition)).isRequired,
};

export default React.memo(SidebarToggle);
