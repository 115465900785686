import React, { useState } from 'react';
import { connect } from 'react-redux';
import { shape, func, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { closeDialog } from '../../../../../actions/dialog';
import dialogTypes from '../../../../../enums/dialogTypes';
import Tabs from '../tabs/tabs';
import Tab from '../tabs/tab';
import Info from './info';

import { buildModuleInPUrl } from '../../../../../utils/buildModuleInPUrl';
import GenericModal from '../common/GenericModal';
import Alert from '../../../../../components/alert';

import { getActiveNodeToHighlight } from '../../../../../selectors/toc';
import { getSharedMedia } from '../../../../../selectors/sharedMedia';
import { getModuleById } from '../../../../../selectors/module';
import { getModuleForDigibook, getTeacherFeaturesEnabledFor } from '../../../../../selectors/digibooks';

function SharedMediaDialog({ sharedMedia, dispatch, teacherFeaturesEnabled, superModule, activeNode, module }) {
  const [activeTab, setActiveTab] = useState(teacherFeaturesEnabled ? 'sharing' : 'info');
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function close() {
    dispatch(closeDialog(sharedMedia.id, dialogTypes.SHARED_MEDIA));
  }

  const goToModuleInPortal = () => {
    window.location.assign(buildModuleInPUrl(language, superModule, module.slug, activeNode));
  };

  const footerContent = (
    <>
      {activeTab === 'info' && (
        <button type="button" className="pbb-btn pbb-btn--inverted" onClick={close}>
          {t('sharedMedia.buttons.close')}
        </button>
      )}
      {activeTab === 'sharing' &&
        (superModule && superModule.id ? (
          <>
            <button className="pbb-btn pbb-btn--inverted" type="button" onClick={close}>
              {t('sharedMedia.buttons.cancel')}
            </button>
            <button className="pbb-btn pbb-btn--icon-right" type="button" onClick={goToModuleInPortal}>
              {t('sharedMedia.buttons.goToPortal')}
              <i className="icon-bb-arrow-right" />
            </button>
          </>
        ) : (
          <button className="pbb-btn" type="button" onClick={close}>
            {t('sharedMedia.buttons.ok')}
          </button>
        ))}
    </>
  );

  return (
    <GenericModal
      title={sharedMedia.name}
      footerContent={footerContent}
      icon="icon-bb-material-own"
      tabBar={
        <Tabs onClick={setActiveTab} activeTab={activeTab}>
          {teacherFeaturesEnabled && <Tab title={t('tabs.sharing')} icon="icon-bb-label-share" id="sharing" />}
          <Tab title={t('tabs.info.title')} icon="icon-bb-modal-info" id="info" />
        </Tabs>
      }
      close={close}
      contentModifier="pbb-modal__info"
    >
      {activeTab === 'sharing' && <Alert message={t('sharedMedia.shareMessage')} />}
      {activeTab === 'info' && <Info sharedMedia={sharedMedia} />}
    </GenericModal>
  );
}

SharedMediaDialog.propTypes = {
  sharedMedia: shape({
    id: string.isRequired,
    name: string.isRequired,
    createdAt: string,
    lastUpdatedAt: string,
    owner: shape({
      id: string.isRequired,
      firstName: string,
      lastName: string,
    }),
    href: string.isRequired,
  }).isRequired,
  dispatch: func.isRequired,
  teacherFeaturesEnabled: bool,
  superModule: shape({
    subjectSlug: string,
    methodSlug: string,
    slug: string,
    id: string,
  }),
  activeNode: shape({ id: string.isRequired }).isRequired,
  module: shape({
    slug: string.isRequired,
  }),

  // eslint-disable-next-line react/no-unused-prop-types
  superModuleId: string,
};

SharedMediaDialog.defaultProps = {
  teacherFeaturesEnabled: false,
  superModuleId: undefined,
  superModule: undefined,
  module: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  sharedMedia: getSharedMedia(state)(ownProps.sharedMediaId),
  teacherFeaturesEnabled: getTeacherFeaturesEnabledFor(state),
  activeNode: getActiveNodeToHighlight(state),
  superModule: ownProps.superModuleId && getModuleById(state, ownProps.superModuleId),
  module: getModuleForDigibook(state),
});

export default connect(mapStateToProps)(SharedMediaDialog);
