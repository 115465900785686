import React, { useState, useEffect } from 'react';
import { func, string, object, bool, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ToolsEnum from '../../../../../enums/tools';
import { setCurrentMathTool, setCurrentTool } from '../../../../../actions/tools';
import { getCurrentMathTool, getCurrentTool, getTools } from '../../../../../selectors/tools';
import ToolSet from '../tools/tool-set';
import Eraser from '../tools/eraser';
import { getPlayerMode } from '../../../../../selectors/player';
import PlayerMode from '../../../../../enums/playerMode';
import { getFeatureToggleFor } from '../../../../../utils/feature-toggle';
import FeatureToggle from '../../../../../enums/feature-toggle';

const { POINTER, PENCIL, MARKER, TEXT_MARKER, CLASSIC_ERASER, SELECTION_ERASER, ANNOTATION, RULER, DRAFTING_COMPASS, SET_SQUARE } = ToolsEnum;

export const Tools = ({ currentTool, tools, showMarkings, setShowMarkings, dispatch, playerMode, currentMathTool }) => {
  const [t] = useTranslation();

  const drawingTools = [PENCIL, MARKER, TEXT_MARKER, ANNOTATION];
  const mathTools = [RULER, DRAFTING_COMPASS, SET_SQUARE];

  const [openTool, setOpenTool] = useState(undefined);
  const [openMathTool, setOpenMathTool] = useState(undefined);

  const handleToolClick = tool => {
    if (tool !== POINTER) setShowMarkings(true);

    setOpenTool(openTool === tool ? undefined : tool);
    dispatch(setCurrentTool(tool));

    if (openMathTool) setOpenMathTool(undefined);
    if (currentMathTool && [TEXT_MARKER, ANNOTATION].includes(tool)) dispatch(setCurrentMathTool(undefined));
  };

  const toggleMarkings = () => {
    handleToolClick(POINTER);
    setShowMarkings(!showMarkings);
  };

  const handleMathToolClick = mathTool => {
    setOpenMathTool(openMathTool === mathTool ? undefined : mathTool);
    dispatch(setCurrentMathTool(mathTool));

    if (openTool) setOpenTool(undefined);
    if ([TEXT_MARKER, ANNOTATION].includes(currentTool)) dispatch(setCurrentTool(POINTER));
  };

  useEffect(() => {
    if (currentTool !== openTool) setOpenTool(undefined);
  }, [currentTool, openTool]);

  useEffect(() => {
    if (currentMathTool !== openMathTool) setOpenMathTool(undefined);
  }, [currentMathTool, openMathTool]);

  return (
    <div className="pbb-sidebar__tools">
      <button
        type="button"
        onClick={() => handleToolClick(POINTER)}
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === POINTER })}
        title={t('sideBar.tools.defaultTool.tooltip')}
      >
        <i className="pbb-sidebar__icon icon-bb-pointer" />
      </button>
      <ToolSet isOpen={drawingTools.includes(openTool)} currentTool={currentTool} onToolClick={handleToolClick} tools={drawingTools} tooltip={t('toolset.tooltip')} />
      {getFeatureToggleFor(FeatureToggle.MATH_TOOLS) && (
        <ToolSet
          currentTool={currentMathTool}
          isOpen={mathTools.includes(openMathTool)}
          onToolClick={handleMathToolClick}
          renderWithOptions={false}
          tools={mathTools}
          tooltip={t('mathToolset.tooltip')}
        />
      )}
      <div className="pbb-sidebar__divider" />
      <button
        type="button"
        onClick={toggleMarkings}
        title={showMarkings ? t('sideBar.tools.hideMarkings.tooltip') : t('sideBar.tools.showMarkings.tooltip')}
        className="pbb-sidebar__button"
        disabled={playerMode === PlayerMode.WHITEPAGE}
      >
        <i className={`pbb-sidebar__icon icon-bb-show${showMarkings ? '-hide' : ''}`} />
      </button>
      <Eraser isOpen={[CLASSIC_ERASER, SELECTION_ERASER].includes(openTool)} currentTool={currentTool} options={tools[CLASSIC_ERASER]} onToolClick={handleToolClick} />
    </div>
  );
};

Tools.propTypes = {
  // Own Props
  showMarkings: bool.isRequired,
  setShowMarkings: func.isRequired,

  // Connected Props
  dispatch: func.isRequired,
  currentTool: string,
  tools: object.isRequired,
  playerMode: oneOf(Object.values(PlayerMode)).isRequired,
  currentMathTool: string,
};

Tools.defaultProps = {
  currentTool: undefined,
  currentMathTool: undefined,
};

const mapStateToProps = state => ({
  currentTool: getCurrentTool(state),
  tools: getTools(state),
  playerMode: getPlayerMode(state),
  currentMathTool: getCurrentMathTool(state),
});

export default connect(mapStateToProps)(Tools);
