import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { connect, useDispatch } from 'react-redux';
import { bool, func } from 'prop-types';

import FavoriteButton from '../../../../../buttons/favoriteButton';
import ShareButton from '../../../../../buttons/shareButton';
import { toggleUserMediaFavorite } from '../../../../../../../../actions/userMedia';
import UserMediaPreviewModal from './UserMaterialPreviewModal';
import api from '../../../../../../../../services/api';
import { openMaterialAssignmentModal, openUserMaterial } from '../../../../../../../../actions/dialog';
import UserMaterialPropType from './user-material-prop-type';
import { getFileType } from '../../../../../../../../utils/filetype';

import MaterialInfo from '../MaterialInfo';
import { isEmbeddableLink } from '../../../../../../../../utils/youtubeUrlParser';
import { getRoles } from '../../../../../../../../selectors/user';
import Role from '../../../../../../../../enums/roles';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../../../enums/analytics';
import useAnalytics from '../../../../../../../../hooks/useAnalytics';

const UserMaterialListItem = ({ userMaterial, isOwner, openOptionsModal, isTeacher }) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const [isPreviewModalActive, setIsPreviewModalActive] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState();

  const toggleFavorite = evt => {
    evt.preventDefault();
    dispatch(toggleUserMediaFavorite(userMaterial));
  };

  const handleMaterialClick = async evt => {
    evt.preventDefault();

    if (!isTeacher && userMaterial.assignment && typeof userMaterial.userMaxScore !== 'number') {
      dispatch(openMaterialAssignmentModal({ ...userMaterial, moduleId: userMaterial.module }, 'user-material', userMaterial.assignment, userMaterial.owner));
    } else if (userMaterial.lti || isEmbeddableLink(userMaterial)) {
      dispatch(openUserMaterial({ ...userMaterial, moduleId: userMaterial.module }, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL));
    } else if (userMaterial.file) {
      const { data: signedUrls } = await api.get(`/studio/user/modules/${userMaterial.module}/user-material/${userMaterial.id}/signed-url`);

      const fileType = getFileType(userMaterial.file.mimeType);

      if (['video', 'audio', 'image'].includes(fileType) || signedUrls.previewUrl) {
        dispatch(openUserMaterial(userMaterial, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL, { signedUrls }));
        return;
      }

      setSelectedMedia({ ...userMaterial, signedUrls });
      setIsPreviewModalActive(true);
    } else if (userMaterial.href) {
      await analytics.capture({
        eventType: ANALYTICS_EVENT_TYPES.USER_MATERIAL_ACCESSED,
        objectId: userMaterial.id,
        subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
      });
      window.open(userMaterial.href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <>
      <li className="pbb-list__item" data-testid="user-media-item" data-id={userMaterial.id}>
        <div className="pbb-list__link-wrap">
          <button className="pbb-list__link pbb-flex-wrap" type="button" onClick={handleMaterialClick} title={userMaterial.name}>
            <MaterialInfo material={userMaterial} isOwner={isOwner} materialType="user-material" />
          </button>
          <div className="pbb-list__actions">
            <FavoriteButton onClick={toggleFavorite} isActive={userMaterial.isFavorite} className="pbb-list__action" />
            <ShareButton onClick={() => openOptionsModal(userMaterial)} className="pbb-list__action" />
          </div>
        </div>
      </li>

      {isPreviewModalActive && createPortal(<UserMediaPreviewModal isOwner={isOwner} userMaterial={selectedMedia} onClose={() => setIsPreviewModalActive(false)} />, document.body)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    isTeacher: getRoles(state).includes(Role.TEACHER),
  };
}

UserMaterialListItem.propTypes = {
  userMaterial: UserMaterialPropType.isRequired,
  isOwner: bool.isRequired,
  openOptionsModal: func.isRequired,
  isTeacher: bool.isRequired,
};

export default connect(mapStateToProps)(UserMaterialListItem);
