import React, { createContext, useState, useContext, useRef } from 'react';
import { func, node, string } from 'prop-types';
import useClickOutside from '../hooks/useClickOutside';

const PopperContext = createContext({
  current: null,
  setCurrent: () => {},
});

export const PopperProvider = ({ children }) => {
  const [current, setCurrent] = useState(null);

  return (
    <PopperContext.Provider
      value={{
        setCurrent: id => setCurrent(current === id ? null : id),
        current,
      }}
    >
      {children}
    </PopperContext.Provider>
  );
};

PopperProvider.propTypes = {
  children: node.isRequired,
};

const Trigger = ({ children, id }) => {
  const { setCurrent } = useContext(PopperContext);
  return children(() => setCurrent(id));
};

Trigger.propTypes = {
  children: func.isRequired,
  id: string,
};

Trigger.defaultProps = {
  id: null,
};

const Content = ({ children, id }) => {
  const { current, setCurrent } = useContext(PopperContext);

  return current === id ? <div className="pbb-popper">{children(() => setCurrent(id))}</div> : null;
};

Content.propTypes = {
  children: func.isRequired,
  id: string,
};

Content.defaultProps = {
  id: null,
};

const Popper = ({ children, id }) => {
  const { current, setCurrent } = useContext(PopperContext);
  const popperRef = useRef(null);

  useClickOutside(popperRef, id === current ? () => setCurrent(null) : () => false);
  return (
    <div ref={popperRef} data-testid="popper">
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          id,
        }),
      )}
    </div>
  );
};
Popper.Trigger = Trigger;
Popper.Content = Content;

Popper.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
};

export default Popper;
