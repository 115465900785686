import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { func, shape, string } from 'prop-types';
import GenericModal from '../common/GenericModal';
import dialogTypes from '../../../../../enums/dialogTypes';
import { closeDialog } from '../../../../../actions/dialog';
import { deleteWhitepage } from '../../../../../actions/whitepages';
import Tabs from '../tabs/tabs';
import Tab from '../tabs/tab';
import Info from './info';
import { getTOCNodeById } from '../../../../../selectors/toc';
import Alert from '../../../../../components/alert';
import TranslationButton from '../../../../../components/translation-button';
import { getWhitepageById } from '../../../../../selectors/whitepages';

const tabs = {
  INFO: 'info',
  DELETE: 'delete',
};

const WhitepageOptionsModal = ({ dispatch, whitepage, tocNode }) => {
  const [t] = useTranslation();

  const [activeTab, setActiveTab] = useState('info');

  const handleClose = useCallback(() => dispatch(closeDialog(whitepage.id, dialogTypes.WHITEPAGE_OPTIONS)), [dispatch, whitepage.id]);

  const handleDelete = useCallback(() => {
    dispatch(deleteWhitepage(whitepage.id, tocNode.id));
    handleClose();
  }, [whitepage.id, tocNode.id, dispatch, handleClose]);

  const footer = useMemo(() => {
    switch (activeTab) {
      case tabs.DELETE:
        return (
          <>
            <TranslationButton onClick={handleClose} label="whitepages.modal.buttons.cancel" className="pbb-btn--inverted" data-testid="cancel-btn" />
            <TranslationButton onClick={handleDelete} label="whitepages.modal.buttons.delete" className="pbb-btn--danger" data-testid="delete-btn" />
          </>
        );
      default:
      case tabs.INFO:
        return <TranslationButton onClick={handleClose} label="whitepages.modal.buttons.close" className="pbb-btn--inverted" data-testid="close-btn" />;
    }
  }, [handleClose, handleDelete, activeTab]);

  const tabBar = useMemo(
    () => (
      <Tabs onClick={setActiveTab} activeTab={activeTab}>
        {whitepage.id && <Tab title={t('tabs.info.title')} id="info" icon="icon-info" />}
        {whitepage.id && <Tab title={t('tabs.delete')} id="delete" icon="icon-trash" />}
      </Tabs>
    ),
    [activeTab, t, whitepage],
  );

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case tabs.DELETE:
        return <Alert message={t('whitepages.modal.alerts.delete')} />;
      default:
      case tabs.INFO:
        return <Info whitepage={whitepage} tocNode={tocNode} />;
    }
  }, [activeTab, t, whitepage, tocNode]);

  return (
    <GenericModal
      icon={`icon-bb-whitepage-${whitepage.paperType}`}
      close={handleClose}
      title={whitepage.title}
      footerContent={footer}
      tabBar={tabBar}
      contentModifier="pbb-modal-whitepages pbb-modal--fixed-height "
    >
      {tabContent}
    </GenericModal>
  );
};

WhitepageOptionsModal.propTypes = {
  whitepage: shape({
    id: string,
    nodeId: string,
    moduleId: string,
    title: string,
    createdAt: string,
    updatedAt: string,
    paperType: string,
  }),
  dispatch: func.isRequired,
  tocNode: shape({
    id: string.isRequired,
  }).isRequired,
};

WhitepageOptionsModal.defaultProps = {
  whitepage: {},
};

const mapStateToProps = (state, ownProps) => ({
  tocNode: getTOCNodeById(state)(ownProps.tocNodeId),
  whitepage: getWhitepageById(state, ownProps.whitepageId),
});

export default connect(mapStateToProps)(WhitepageOptionsModal);
