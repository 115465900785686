import React from 'react';
import classNames from 'classnames';
import { func, arrayOf, bool, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ShareButton from '../../../buttons/shareButton';
import BookmarkButton from '../../../buttons/bookmarkButton';

const TocItem = ({ onNodeClicked, node, expanded, active, subnodes, level = 1, showShareButton, onShareButtonClicked, onShareIndicatorClicked, hasSharedSolutions }) => {
  const [t] = useTranslation();

  const stopPropagation = action => event => {
    event.stopPropagation();
    action(node);
  };
  return (
    <li
      className={classNames(`pbb-toc__item--level-${level}`, 'pbb-toc__item', {
        'pbb-expanded': expanded && level === 1,
        'pbb-toc__item--sub': level > 1,
        'pbb-toc__item--active': active,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classNames('pbb-toc__item-wrapper', {
          'pbb-toc__item-wrapper--active': active, // do not highlight expanded
        })}
        href="#"
        onClick={e => {
          e.preventDefault();
          onNodeClicked(node);
        }}
      >
        <BookmarkButton />

        <div className="pbb-toc__item-title-wrapper">
          {node.displayPrefix && <div className="pbb-toc__item-subtitle">{node.prefix}</div>}
          <div className="pbb-toc__item-title">{node.title}</div>
        </div>

        {hasSharedSolutions &&
          (showShareButton ? (
            <button
              type="button"
              className="pbb-toc__option pbb-shared-solution-indicator"
              onClick={stopPropagation(onShareIndicatorClicked)}
              title={t('sharing.solutions.tocIndicator.tooltip')}
            >
              <i className="bc-icon bc-icon-lightbulb" />
            </button>
          ) : (
            <div className="pbb-toc__option pbb-shared-solution-indicator" title={t('sharing.solutions.tocIndicator.tooltip')}>
              <i className="bc-icon bc-icon-lightbulb" />
            </div>
          ))}

        {showShareButton && <ShareButton className="pbb-toc__option" onClick={stopPropagation(onShareButtonClicked)} />}
      </a>
      {subnodes && expanded && <ul className={`pbb-toc__list-level-${level + 1}`}>{subnodes}</ul>}
    </li>
  );
};

TocItem.propTypes = {
  onNodeClicked: func.isRequired,
  node: shape({ displayPrefix: bool, prefix: string, title: string }),
  expanded: bool,
  active: bool,
  subnodes: arrayOf(shape({ displayPrefix: bool, prefix: string, title: string })),
  level: number,
  onShareButtonClicked: func,
  onShareIndicatorClicked: func,
  showShareButton: bool,
  hasSharedSolutions: bool,
};

TocItem.defaultProps = {
  node: { displayPrefix: false, prefix: '', title: '' },
  expanded: false,
  active: false,
  subnodes: [],
  level: 1,
  onShareButtonClicked: () => {},
  onShareIndicatorClicked: () => {},
  showShareButton: false,
  hasSharedSolutions: false,
};

export default TocItem;
