import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { func, string, shape } from 'prop-types';
import Colors, { backgroundColors } from '../../../../../enums/colors';
import Sizes from '../../../../../enums/sizes';
import tools from '../../../../../enums/tools';

const buildColorClassName = tool => color => `pbb-${tool}-color pbb-${tool}-color--${color}`;

const ToolOptions = ({ variant, onColorClick, onSizeClick, onBackgroundColorClick, currentOptions }) => {
  const getColorClassName = buildColorClassName(variant);

  const [t] = useTranslation();

  return (
    <>
      <div className={classNames('pbb-menu__colors', `pbb-menu__colors--${variant}`)}>
        {Object.values(Colors).map(color => (
          <button
            onClick={() => onColorClick(color)}
            key={color}
            type="button"
            className={classNames(getColorClassName(color), 'pbb-sidebar__button', { 'pbb-sidebar__button--active': currentOptions.color === color })}
            title={t(`colors.${color}`)}
          >
            {variant === tools.ANNOTATION ? <i role="img" className="pbb-sidebar__icon icon-bb-annotation" /> : <span className="indicator" />}
          </button>
        ))}
      </div>
      {variant !== tools.TEXT_MARKER && (
        <>
          <div className="divider-line" />
          <div className={classNames('pbb-menu__sizes', `pbb-menu__sizes--${variant}`)}>
            {Object.values(Sizes).map(size => (
              <button
                key={size}
                onClick={() => onSizeClick(size)}
                type="button"
                title={t(`sizes.tool.${size}`)}
                className={classNames(getColorClassName(currentOptions.color), `size--${size}`, 'pbb-sidebar__button', {
                  'pbb-sidebar__button--active': currentOptions.size === size,
                })}
              >
                {variant === tools.ANNOTATION ? <i role="img" className="pbb-sidebar__icon icon-bb-annotation" /> : <span className="indicator" />}
              </button>
            ))}
          </div>
        </>
      )}
      {variant === tools.ANNOTATION && (
        <>
          <div className="divider-line" />
          <div className="pbb-menu__backgrounds">
            {Object.values(backgroundColors).map(bgColor => (
              <button
                key={bgColor}
                onClick={() => onBackgroundColorClick(bgColor)}
                type="button"
                title={t(`backgroundColors.${bgColor}`)}
                className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentOptions.backgroundColor === bgColor})}
              >
                {bgColor === backgroundColors.WHITE && <i role="img" className="pbb-sidebar__icon icon-bb-rounded-rectangle" />}
                {bgColor === backgroundColors.TRANSPARENT && <i role="img" className="pbb-sidebar__icon icon-bb-rounded-rectangle-strikethrough" />}
              </button>
            ))}
          </div>
        </>
      )}
    </>
  );
};

ToolOptions.propTypes = {
  variant: string.isRequired,
  onColorClick: func.isRequired,
  onSizeClick: func.isRequired,
  onBackgroundColorClick: func,
  currentOptions: shape({
    color: string,
    size: string,
    backgroundColor: string,
  }),
};

ToolOptions.defaultProps = {
  currentOptions: {},
  onBackgroundColorClick: null,
};

export default ToolOptions;
