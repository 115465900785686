import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, shape, string, bool, func, object, number, any } from 'prop-types';
import { createPortal } from 'react-dom';

import MediaDialogs from './media-dialogs';

import MedialinkOptionsDialog from './options/medialinkOptions';
import MediaLinkChooser from './mediaDialogs/chooser';
import PrintPageRangeModal from './PrintPageRangeModal';
import TocNodeOptionsModal from './options/tocNodeOptions';

import { getOpenDialogs, getOpenMinisite, getMedialinksChooserData, getOpenMaterialAssignment } from '../../../../selectors/dialogs';

import { getCurrentDigibook } from '../../../../selectors/digibooks';

import dialogTypes from '../../../../enums/dialogTypes';
import SharedMediaDialog from './sharedMedia';
import { UserSettingsConsumer } from '../../context/user-settings-context';
import NotesModal from './notes/notes-modal';
import WhitepageOptionsModal from './whitepages/whitepage-options-modal';
import WebsiteModal from './mediaDialogs/website-modal';
import { closeMinisite } from '../../../../actions/dialog';
import { formatTeacherName } from '../../../../utils/nameFormatting';
import MaterialAssignmentModal from './components/MaterialAssignmentModal';

export const Dialogs = ({ openDialogs, superModuleId, medialinksChooser, openMinisite, digibook, dispatch, openMaterialAssignment }) => {
  function renderDialogByType({ id, dialogType, ...data }) {
    switch (dialogType) {
      case dialogTypes.SHARED_MEDIA:
        return <SharedMediaDialog key={`${id}_${dialogType}`} sharedMediaId={id} superModuleId={superModuleId} />;
      case dialogTypes.OPTIONS: {
        return <MedialinkOptionsDialog key={`${id}_${dialogType}`} medialinkId={id} superModuleId={superModuleId} />;
      }
      case dialogTypes.PRINT_PAGE: {
        return (
          <PrintPageRangeModal
            digibook={digibook}
            key={dialogType}
            dispatch={dispatch}
            activeNodes={data.activeNodes}
            visiblePages={data.visiblePages}
            teacherFeaturesEnabled={digibook.teacherFeaturesEnabled}
          />
        );
      }
      case dialogTypes.TOCNODE_OPTIONS: {
        return <TocNodeOptionsModal digibook={digibook} key={dialogType} node={data.node} dispatch={dispatch} />;
      }

      case dialogTypes.NOTE_EDITOR: {
        return <NotesModal key={dialogType} tocNodeId={data.tocNodeId} dispatch={dispatch} noteId={id} />;
      }

      case dialogTypes.WHITEPAGE_OPTIONS: {
        return <WhitepageOptionsModal key={dialogType} tocNodeId={data.tocNodeId} dispatch={dispatch} whitepageId={id} />;
      }

      default: {
        return null;
      }
    }
  }

  const dialogsInfo = openDialogs.filter(x => !x.dialogType);

  return (
    <>
      {openDialogs && openDialogs.filter(x => x.dialogType).map(dialog => renderDialogByType(dialog))}
      {dialogsInfo.length > 0 && (
        <UserSettingsConsumer>
          {({ sidebarAnchor, activeDrawer }) => <MediaDialogs sidebarAnchor={sidebarAnchor} activeDrawer={activeDrawer} dialogsInfo={dialogsInfo} superModuleId={superModuleId} />}
        </UserSettingsConsumer>
      )}
      {medialinksChooser && (
        <MediaLinkChooser
          key={medialinksChooser.linkAreaId}
          linkAreaId={medialinksChooser.linkAreaId}
          title={medialinksChooser.title}
          medialinks={medialinksChooser.medialinks}
          mousePosition={medialinksChooser.mousePosition}
        />
      )}
      {openMinisite &&
        createPortal(
          <WebsiteModal
            width={openMinisite.medialink.miniSite.width}
            height={openMinisite.medialink.miniSite.height}
            name={openMinisite.medialink.name}
            iconClassName={openMinisite.medialink.icon}
            reload={openMinisite.medialink.miniSite.reload}
            url={openMinisite.miniSiteUrl}
            onClose={() => {
              dispatch(closeMinisite(openMinisite.medialink.id));
            }}
          />,
          document.body,
        )}
      {openMaterialAssignment && (
        <UserSettingsConsumer>
          {({ sidebarAnchor }) => (
            <div className={`material-assignment-modal-container pbb-panel--${sidebarAnchor}`}>
              <MaterialAssignmentModal
                dispatch={dispatch}
                material={openMaterialAssignment.material}
                assignment={openMaterialAssignment.assignment}
                sharedBy={formatTeacherName(openMaterialAssignment.owner)}
              />
            </div>
          )}
        </UserSettingsConsumer>
      )}
    </>
  );
};

Dialogs.propTypes = {
  // Own Props
  superModuleId: string,

  // Connected Props
  openDialogs: arrayOf(
    shape({
      id: string,
      isInFront: bool,
      dialogType: string,
      data: shape({
        visiblePages: arrayOf(number),
        activeNodes: arrayOf(object),
        node: object,
      }),
    }),
  ),
  openMinisite: shape({
    medialink: any,
    miniSiteUrl: string,
  }),
  medialinksChooser: shape({
    title: string,
    medialinks: arrayOf(shape({ id: string, name: string, icon: string })),
    mousePosition: shape({ x: number, y: number }),
  }),
  digibook: shape({
    teacherFeaturesEnabled: bool,
    answerLayer: string,
    totalPages: number,
  }).isRequired,
  openMaterialAssignment: shape({
    material: MaterialAssignmentModal.propTypes.material,
    assignment: MaterialAssignmentModal.propTypes.assignment,
  }),
  dispatch: func.isRequired,
};

Dialogs.defaultProps = {
  superModuleId: undefined,
  openDialogs: [],
  openMinisite: undefined,
  medialinksChooser: undefined,
  openMaterialAssignment: undefined,
};

const mapStateToProps = state => ({
  openDialogs: getOpenDialogs(state),
  openMinisite: getOpenMinisite(state),
  medialinksChooser: getMedialinksChooserData(state),
  digibook: getCurrentDigibook(state),
  openMaterialAssignment: getOpenMaterialAssignment(state),
});

export default connect(mapStateToProps)(Dialogs);
