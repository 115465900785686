import React from 'react';

const BookmarkButton = () => {
  /* When implementing toggle the class, not via css => <i className="icon-bb-bookmark-full" /> */

  return (
    <button className="pbb-toc__item-bookmark" type="button">
      <i className="icon-bb-bookmark" />
    </button>
  );
};

export default BookmarkButton;
