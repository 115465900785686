import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { func, bool, string, arrayOf, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import materialSortings from '../../../../../../enums/materialSorting';

import { UserSettingsContext } from '../../../../context/user-settings-context';

function Footer({ openFilterSettings, materialCategoriesToHide, module }) {
  const { t } = useTranslation();
  const { materialSorting, setMaterialSorting, showOnlyFavoriteMaterial, setShowOnlyFavoriteMaterial } = useContext(UserSettingsContext);

  useEffect(() => {
    if (module.defaultSorting) {
      setMaterialSorting(module.defaultSorting);
    }
  }, [module.defaultSorting, setMaterialSorting]);

  return (
    <div className="pbb-footer">
      <div className="pbb-mask" />
      {!module.disableLessonSorting && <span className="pbb-footer__intro">{t('drawer.material.footer.sort')}</span>}
      <div className="pbb-footer__wrapper">
        {!module.disableLessonSorting && (
          <div className="pbb-custom-select">
            <select data-testid="material-footer-sort" value={materialSorting} onChange={e => setMaterialSorting(e.target.value)}>
              <option value={materialSortings.CATEGORY}>{t('drawer.material.footer.category')}</option>
              <option value={materialSortings.LESSON}>{t('drawer.material.footer.courseProgress')}</option>
            </select>
          </div>
        )}

        <button
          className={classNames('footer__button', { 'footer__button--active': showOnlyFavoriteMaterial })}
          type="button"
          title={t(showOnlyFavoriteMaterial ? 'drawer.material.footer.favorites.showAll' : 'drawer.material.footer.favorites.showOnlyFavorites')}
          onClick={() => setShowOnlyFavoriteMaterial(!showOnlyFavoriteMaterial)}
        >
          <i className="icon-favorite" />
          <i className="icon-favorite-full" />
        </button>

        <button className="footer__button footer__button--filter" type="button" title={t('drawer.material.footer.settings')} onClick={openFilterSettings}>
          <i className={materialCategoriesToHide.length > 0 ? 'icon-bb-material-hide' : 'icon-bb-material-show'} />
          {materialCategoriesToHide.length > 0 && <span>{materialCategoriesToHide.length}</span>}
        </button>
      </div>
    </div>
  );
}

Footer.propTypes = {
  module: shape({
    disableLessonSorting: bool,
  }).isRequired,
  openFilterSettings: func.isRequired,
  materialCategoriesToHide: arrayOf(string),
};

Footer.defaultProps = {
  materialCategoriesToHide: [],
};

export default Footer;
