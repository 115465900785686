import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Collapsible from '../../../../../../components/collapsible';
import { getSlideSetsForCurrentPagesGroupedByNode } from '../../../../../../selectors/slideSets';
import { getActiveNodesForMediaLinks } from '../../../../../../selectors/toc';
import TocNodeTitle from '../tocnode-title';
import Alert from '../../../../../../components/alert';
import SlidSetListItem from './SlideSetListItem';
import AddButton from './AddButton';

function SlideSetList() {
  const [t] = useTranslation();
  const activeNodes = useSelector(getActiveNodesForMediaLinks);
  const slideSetsByNode = useSelector(getSlideSetsForCurrentPagesGroupedByNode);

  if (!activeNodes.length) {
    return null;
  }

  let higherLevelSlidesCount = 0;

  const renderItemContent = (slideSets = [], isDeepestNode, nodeId) => {
    if (!slideSets.length && isDeepestNode) {
      let alertMessage = t('drawer.slideSets.slideSetOnHigherNode');
      if (higherLevelSlidesCount === 0) {
        alertMessage = t('drawer.slideSets.noSlideSet');
      }

      return (
        <ul className="pbb-list pbb-list--borders" data-testid={`slideSetItem-${nodeId}`}>
          <li>
            <Alert message={alertMessage} className="pbb-alert--spaced" />
          </li>
          <AddButton text={t('drawer.slideSets.buttons.add')} toolTip={t('drawer.slideSets.buttons.addTooltip')} nodeId={nodeId} />
        </ul>
      );
    }

    const sortedSlideSets = slideSets.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <ul className="pbb-list pbb-list--borders" data-testid={`slideSetItem-${nodeId}`}>
        <AddButton text={t('drawer.slideSets.buttons.add')} toolTip={t('drawer.slideSets.buttons.addTooltip')} nodeId={nodeId} />
        {sortedSlideSets.map(slideSet => (
          <SlidSetListItem slideSet={slideSet} key={`slideset-${slideSet.id}-version-${slideSet.versionId}`} nodeId={nodeId} />
        ))}
      </ul>
    );
  };

  return (
    <Collapsible>
      {activeNodes.map((node, teller) => {
        const isDeepestNode = teller === activeNodes.length - 1;
        if (!isDeepestNode) {
          higherLevelSlidesCount += slideSetsByNode[node.id]?.length || 0;
        }
        return (
          <Collapsible.Item key={node.id}>
            <Collapsible.Title>
              <span className="pbb-collapsible__title-text" data-testid={`title-${node.id}`}>
                {node.displayPrefix && <span className="pbb-collapsible__subtitle">{node.prefix}</span>}
                <span className="pbb-collapsible__title">
                  <TocNodeTitle tocNode={node} />
                </span>
              </span>
            </Collapsible.Title>
            <Collapsible.Content>{renderItemContent(slideSetsByNode[node.id], isDeepestNode, node.id)}</Collapsible.Content>
          </Collapsible.Item>
        );
      })}
    </Collapsible>
  );
}

export default SlideSetList;
