import React from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import PaperType from '../../../../../../enums/paperType';
import { addWhitepage } from '../../../../../../actions/whitepages';
import Popper from '../../../../../../components/popper';

function AddWhitepagePopper({ tocNodeId, showOnlyFavoriteWhitepages }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const createWhitepage = (nodeId, paperType, asFavorite) => dispatch(addWhitepage(nodeId, paperType, asFavorite));

  return (
    <li className="pbb-list__item" style={{ position: 'relative' }}>
      <Popper id={tocNodeId}>
        <Popper.Trigger>
          {togglePopper => (
            <div className="pbb-list__link-wrap">
              <button
                type="button"
                className="pbb-list__link"
                title={t('drawer.whitepages.buttons.add_tooltip')}
                data-testid="popper-trigger"
                id="add-whitepage"
                onClick={togglePopper}
              >
                <i className="pbb-list__link-icon icon-plus" />
                <div className="pbb-list__link-text">{t('drawer.whitepages.buttons.add')}</div>
              </button>
            </div>
          )}
        </Popper.Trigger>
        <Popper.Content>
          {togglePopper => (
            <ul className="pbb-list pbb-list--padded">
              {Object.values(PaperType).map(type => (
                <li key={`icon-bb-whitepage-${type}`} className="pbb-list__item">
                  <div className="pbb-list__link-wrap">
                    <button
                      className="pbb-list__link"
                      type="button"
                      data-testid={`add-whitepage-${type}`}
                      onClick={() => {
                        createWhitepage(tocNodeId, type, showOnlyFavoriteWhitepages);
                        togglePopper();
                      }}
                    >
                      <MaterialIcon materialType="whitepage" paperType={type} className="pbb-list__link-icon" />
                      <span className="pbb-list__link-text">{t(`drawer.whitepages.types.${type}`)}</span>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </Popper.Content>
      </Popper>
    </li>
  );
}

AddWhitepagePopper.propTypes = {
  tocNodeId: string.isRequired,
  showOnlyFavoriteWhitepages: bool.isRequired,
};

export default AddWhitepagePopper;
