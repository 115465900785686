const dialogTypes = {
  OPTIONS: 'options',
  CONFIRMATION: 'confirmation',
  SHARED_MEDIA: 'shared_media',
  TOCNODE_OPTIONS: 'tocnode_options',
  PRINT_PAGE: 'print_page',
  NOTE_EDITOR: 'note_editor',
  WHITEPAGE_OPTIONS: 'whitepage_options',
};

export default dialogTypes;
