import { number, oneOf, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SlideSetIcon from '@pelckmans/business-components/icons/SlideSet';
import SlideOverviewIcon from '@pelckmans/business-components/icons/SlideOverview';
import PencilWithSquareIcon from '@pelckmans/business-components/icons/PencilWithSquare';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import SlideDuplicateIcon from '@pelckmans/business-components/icons/SlideDuplicate';
import { MenuItem, MenuDivider } from '@pelckmans/business-components/components/action-menu';
import { deleteUserSlideSet, duplicateSlideSet } from '../../../../../../actions/slideSets';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import SlideSetStates from '../../../../../../enums/slideSetState';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import Confirmation from '../../../dialogs/confirmation';

export default function OptionsMenuActions({ slideSet, nodeId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name, id } = slideSet;
  const [confirmationShown, setConfirmationShown] = useState(false);
  const analytics = useAnalytics();
  const moduleId = useSelector(getCurrentModuleId);

  const handleDelete = () => {
    dispatch(deleteUserSlideSet(slideSet));
    setConfirmationShown(false);
  };

  const logDuplicateEvent = slideSetId => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_DUPLICATED,
      objectId: slideSetId.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  const handleDuplicate = async () => {
    const duplicatedSlideSet = await dispatch(duplicateSlideSet(slideSet, nodeId));

    logDuplicateEvent(duplicatedSlideSet.id);

    return window.open(buildDoceoUrl(duplicatedSlideSet, true), '_blank', 'noopener,noreferrer');
  };

  const logPresentEvent = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: moduleId,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  const logEditModeEvent = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_EDIT_MODE_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  const logOverviewEvent = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_OVERVIEW_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  return (
    <>
      <MenuItem
        href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS)}
        onClick={logPresentEvent}
        target="_blank"
        rel="noopener noreferrer"
        title={`${name} ${t('drawer.slideSets.actionMenu.present').toLowerCase()}`}
        role="link"
      >
        <SlideSetIcon />
        <span>{t('drawer.slideSets.actionMenu.present')}</span>
        <i role="presentation" className="icon-bb-opentab icon-fw" style={{ marginLeft: 16 }} />
      </MenuItem>
      <MenuDivider />
      <MenuItem
        href={buildDoceoUrl(slideSet, false)}
        target="_blank"
        rel="noopener noreferrer"
        title={`${name} ${t('drawer.slideSets.actionMenu.overview').toLowerCase()}`}
        onClick={logOverviewEvent}
        role="link"
      >
        <SlideOverviewIcon />
        <span>{t('drawer.slideSets.actionMenu.overview')}</span>
      </MenuItem>
      {slideSet.userId && (
        <>
          <MenuItem
            href={buildDoceoUrl(slideSet, true)}
            onClick={logEditModeEvent}
            target="_blank"
            rel="noopener noreferrer"
            title={`${name} ${t('drawer.slideSets.actionMenu.edit').toLowerCase()}`}
            role="link"
          >
            <PencilWithSquareIcon />
            <span>{t('drawer.slideSets.actionMenu.edit')}</span>
          </MenuItem>
          <MenuItem role="button" onClick={() => setConfirmationShown(true)} title={`${name} ${t('actions.delete').toLowerCase()}`}>
            <BinIcon />
            <span>{t('actions.delete')}</span>
          </MenuItem>
        </>
      )}
      <MenuItem onClick={handleDuplicate} disabled={slideSet.state !== 'PUBLISHED'} title={`${name} ${t('drawer.slideSets.actionMenu.duplicate').toLowerCase()}`} role="button">
        <SlideDuplicateIcon />
        <span>{t('drawer.slideSets.actionMenu.duplicate')}</span>
      </MenuItem>
      {confirmationShown && (
        <Confirmation
          title={`${t('actions.delete')} ${name}`}
          icon={<BinIcon />}
          message={t('drawer.slideSets.actionMenu.deleteMessage')}
          confirmationText={t('actions.delete')}
          confirmationClassName="pbb-btn--danger"
          cancellationText={t('confirmation.cancel')}
          onConfirm={handleDelete}
          onCancel={() => setConfirmationShown(false)}
        />
      )}
    </>
  );
}

OptionsMenuActions.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    versionId: number.isRequired,
    state: oneOf(Object.values(SlideSetStates)),
  }).isRequired,
  nodeId: string.isRequired,
};
