import React from 'react';
import { shape, func, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/intl';

const Info = ({ sharedMedia, t }) => {
  const sharedByTeacher = !!sharedMedia.owner;

  return (
    <div data-testid="wrapper" className="pbb-modal__info-wrapper pbb-modal__info-wrapper--no-edit">
      <div className="pbb-modal__info" style={{ margin: 0 }}>
        <div className="pbb-modal__title-wrapper">
          <div className="pbb-modal__title">
            <span>{sharedMedia.name}</span>
          </div>
        </div>
        <table className="pbb-modal__specs">
          <tbody>
            {sharedMedia.createdAt && (
              <>
                <tr>
                  <td className="pbb-modal__specs-title">{t('tabs.info.labels.createdAt')}</td>
                  <td data-testid="created-at" className="pbb-modal__specs-info">
                    {formatDate(new Date(sharedMedia.createdAt))}
                  </td>
                </tr>
                <tr className="pbb-modal__specs-spacer" />
              </>
            )}
            {sharedMedia.lastUpdatedAt && (
              <>
                <tr>
                  <td className="pbb-modal__specs-title">{t('tabs.info.labels.updatedAt')}</td>
                  <td data-testid="last-updated-at" className="pbb-modal__specs-info">
                    {formatDate(new Date(sharedMedia.lastUpdatedAt))}
                  </td>
                </tr>
                <tr className="pbb-modal__specs-spacer" />
              </>
            )}
            <tr>
              <td className="pbb-modal__specs-title">{t('sharedMedia.category')}</td>
              <td className="pbb-modal__specs-info">{sharedByTeacher ? t('sharedMedia.sharedByTeacher') : t('sharedMedia.sharedMedia')}</td>
            </tr>
            <tr className="pbb-modal__specs-spacer" />
            {sharedMedia.owner && (
              <tr>
                <td className="pbb-modal__specs-title">{t('sharedMedia.teacher')}</td>
                <td className="pbb-modal__specs-info">{`${sharedMedia.owner.firstName} ${sharedMedia.owner.lastName}`}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Info.propTypes = {
  sharedMedia: shape({
    name: string.isRequired,
    createdAt: string,
    lastUpdatedAt: string,
    owner: shape({}),
  }).isRequired,
  t: func.isRequired,
};

export default withTranslation()(Info);
