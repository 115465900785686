import { combineReducers } from 'redux';

import auth from './authReducer';
import player from './playerReducer';
import navigation from './navigationReducer';
import dialogs from './dialogsReducer';
import tools from './toolsReducer';
import module from './moduleReducer';
import medialinks from './medialinksReducer';
import sharedMedia from './sharedMediaReducer';
import error from './errorReducer';
import notes from './notesReducer';
import whitepages from './whitepagesReducer';
import sharing from './sharingReducer';
import featureToggles from './featureToggleReducer';
import userMedia from './userMediaReducer';
import slideSets from './slideSetsReducer';
import popUpManual from './popUpManualReducer';

export default combineReducers({
  auth,
  player,
  navigation,
  dialogs,
  tools,
  module,
  medialinks,
  sharedMedia,
  error,
  notes,
  whitepages,
  sharing,
  featureToggles,
  userMedia,
  slideSets,
  popUpManual,
});
