import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { string, func, shape, bool, arrayOf, number, oneOf } from 'prop-types';

import { brushSizes } from '../../../../../enums/sizes';
import Tools from '../../../../../enums/tools';
import markingPosition from '../../../../../enums/markingPosition';
import Confirmation from '../../dialogs/confirmation';

import { deleteMarkingsForSpread, deleteMarkingsForSinglePage, setSize } from '../../../../../actions/tools';
import { getPageNumbersToShow } from '../../../../../selectors/rendering';
import { getTotalPagesForDigibook } from '../../../../../selectors/digibooks';
import { calculateSpreadForPageNumbers } from '../../../../../utils/calculateSpreadForPageNumbers';
import { getPlayerMode } from '../../../../../selectors/player';
import PlayerMode from '../../../../../enums/playerMode';

function Eraser({ isOpen, currentTool, onToolClick, options, dispatch, pageNumbersToShow, totalPages, playerMode }) {
  const [lastSelectedEraser, setLastSelectedEraser] = useState(Tools.CLASSIC_ERASER);
  const [confirmationShown, setConfirmationShown] = useState(false);

  const [t] = useTranslation();

  function clearAllEraser() {
    if (playerMode !== PlayerMode.WHITEPAGE) {
      const spread = calculateSpreadForPageNumbers(pageNumbersToShow, totalPages);
      const allPagesVisible = spread.length === pageNumbersToShow.length;
      if (allPagesVisible) {
        dispatch(deleteMarkingsForSpread(spread));
      } else if (spread[0] === pageNumbersToShow[0]) {
        dispatch(deleteMarkingsForSinglePage(spread, markingPosition.LEFT));
      } else {
        dispatch(deleteMarkingsForSinglePage(spread, markingPosition.RIGHT));
      }
    }
    document.dispatchEvent(new CustomEvent('erase-all-clicked'));
    setConfirmationShown(false);
    onToolClick(Tools.POINTER);
  }

  function currentIcon() {
    if (lastSelectedEraser === Tools.CLASSIC_ERASER) return 'icon-bb-eraser';
    return 'icon-bb-eraser-framed';
  }

  return (
    <div className="pbb-tool-wrapper">
      <button
        type="button"
        onClick={() => onToolClick(lastSelectedEraser)}
        title={[Tools.CLASSIC_ERASER, Tools.SELECTION_ERASER].includes(currentTool) ? t(`${currentTool}Tool.tooltip`) : t('classic_eraserTool.tooltip')}
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === lastSelectedEraser, 'pbb-sidebar__button--selected': isOpen })}
      >
        <i className={classNames('pbb-sidebar__icon', currentIcon())} />
      </button>
      {isOpen && (
        <div className="pbb-sidebar__menu">
          <div className="pbb-menu__erasers">
            <button
              type="button"
              title={t('classic_eraserTool.tooltip')}
              onClick={() => {
                onToolClick(Tools.CLASSIC_ERASER);
                setLastSelectedEraser(Tools.CLASSIC_ERASER);
              }}
              className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === Tools.CLASSIC_ERASER })}
            >
              <i className="pbb-sidebar__icon icon-bb-eraser" />
            </button>
            <button
              type="button"
              title={t('selection_eraserTool.tooltip')}
              onClick={() => {
                onToolClick(Tools.SELECTION_ERASER);
                setLastSelectedEraser(Tools.SELECTION_ERASER);
              }}
              className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === Tools.SELECTION_ERASER })}
            >
              <i className="pbb-sidebar__icon icon-bb-eraser-framed" />
            </button>
            <button type="button" title={t('eraser.clearAll.tooltip')} onClick={() => setConfirmationShown(true)} className="pbb-sidebar__button">
              <i className="pbb-sidebar__icon icon-bb-eraser-all" />
            </button>
          </div>
          {currentTool === Tools.CLASSIC_ERASER && (
            <>
              <div className="divider-line" />
              <div className="pbb-menu__sizes pbb-menu__sizes--eraser">
                {Object.keys(brushSizes[Tools.CLASSIC_ERASER]).map(size => (
                  <button
                    key={size}
                    onClick={() => dispatch(setSize(size))}
                    type="button"
                    title={t(`sizes.eraser.${size}`)}
                    className={classNames(`size--${size}`, 'pbb-sidebar__button', {
                      'pbb-sidebar__button--active': options.size === size,
                    })}
                  >
                    <span className="indicator" />
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      )}

      {confirmationShown && (
        <Confirmation
          title={t('eraser.clearAll.title')}
          icon="icon-bb-eraser-all"
          message={t('eraser.clearAll.message')}
          confirmationText={t('eraser.clearAll.button')}
          confirmationClassName="pbb-btn--danger"
          cancellationText={t('confirmation.cancel')}
          onConfirm={clearAllEraser}
          onCancel={() => setConfirmationShown(false)}
        />
      )}
    </div>
  );
}

Eraser.propTypes = {
  // Own Props
  isOpen: bool,
  currentTool: string,
  onToolClick: func.isRequired,
  options: shape({
    type: string,
    size: string,
  }).isRequired,

  // Connected Props
  dispatch: func.isRequired,
  pageNumbersToShow: arrayOf(number),
  totalPages: number,
  playerMode: oneOf(Object.values(PlayerMode)),
};

Eraser.defaultProps = {
  pageNumbersToShow: [],
  currentTool: undefined,
  isOpen: false,
  totalPages: undefined,
  playerMode: PlayerMode.BOOK,
};

const mapStateToProps = state => ({
  pageNumbersToShow: getPageNumbersToShow(state),
  totalPages: getTotalPagesForDigibook(state),
  playerMode: getPlayerMode(state),
});

export default connect(mapStateToProps)(Eraser);
