import get from 'get-value';
import { createSelector } from 'reselect';
import Role from '../enums/roles';
import { highestRole } from '../utils/roles';

export const getRoles = state => get(state, ['auth', 'user', 'roles']);

export const getUserFullName = createSelector(
  // eslint-disable-line import/prefer-default-export
  state => get(state, ['auth', 'user', 'givenname']),
  state => get(state, ['auth', 'user', 'surname']),
  (firstName, lastName) => {
    const names = [];
    if (firstName) names.push(firstName);
    if (lastName) names.push(lastName);
    return names.join(' ');
  },
);

const SLIDE_SETS_REVIEWER_ROLES = [Role.SYSADMIN, Role.PRODUCTION, Role.EDITOR, Role.PUBLISHER];
export const getIsSlideSetsReviewer = createSelector([getRoles], roles => SLIDE_SETS_REVIEWER_ROLES.includes(highestRole(roles)));
