import update from 'immutability-helper';
import keyBy from 'lodash/keyBy';
import { SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS, SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS } from '../actions/actionNames';

export const initialState = {
  byVersionId: {},
  byTocNodeId: {},
  nodesFetched: [],
};

export default function slideSetsReducer(state = initialState, action) {
  switch (action?.type) {
    case SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS: {
      const { nodeId, slideSets } = action.payload;

      const keyByVersionId = keyBy(slideSets, 'versionId');

      return update(state, {
        byVersionId: { $merge: keyByVersionId || {} },
        byTocNodeId: { [nodeId]: { $set: Object.keys(keyByVersionId) } },
        nodesFetched: { $push: [nodeId] },
      });
    }
    case SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS: {
      const { slideSet } = action.payload;

      return update(state, {
        byVersionId: { $unset: [slideSet.versionId] },
        byTocNodeId: {
          [slideSet.nodeId]: {
            $apply: tocNodeIds => tocNodeIds.filter(id => id !== String(slideSet.versionId)),
          },
        },
      });
    }
    default: {
      return state;
    }
  }
}
