import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UserSettingsContext } from '../../../../context/user-settings-context';

function Footer() {
  const [t] = useTranslation();
  const { showOnlyFavoriteWhitepages, setShowOnlyFavoriteWhitepages } = useContext(UserSettingsContext);

  return (
    <div className="pbb-footer">
      <div className="pbb-mask" />
      <div className="pbb-footer__wrapper">
        <div className="footer__button-with-label">
          <span className="footer__button-label">
            {t(showOnlyFavoriteWhitepages ? 'drawer.material.footer.favorites.showAll' : 'drawer.material.footer.favorites.showOnlyFavorites')}
          </span>
          <button
            className={classNames('footer__button', { 'footer__button--active': showOnlyFavoriteWhitepages })}
            type="button"
            title={t(showOnlyFavoriteWhitepages ? 'drawer.material.footer.favorites.showAll' : 'drawer.material.footer.favorites.showOnlyFavorites')}
            onClick={() => setShowOnlyFavoriteWhitepages(!showOnlyFavoriteWhitepages)}
            data-testid="filter-favorite-whitepages-button"
          >
            <i className="icon-favorite" />
            <i className="icon-favorite-full" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
