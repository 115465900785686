const tools = {
  POINTER: 'pointer',
  ANNOTATION: 'annotation',
  PENCIL: 'pencil',
  MARKER: 'marker',
  TEXT_MARKER: 'textmarker',
  CLASSIC_ERASER: 'classic_eraser',
  SELECTION_ERASER: 'selection_eraser',
  ANSWER_REVEAL: 'answerReveal',
  ANSWER_HIDE: 'answerHide',
  ZOOM_SELECT: 'zoomSelect',
  RULER: 'ruler',
  DRAFTING_COMPASS: 'drafting_compass',
  SET_SQUARE: "set_square"
};

export default tools;
