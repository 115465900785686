import React from 'react';
import { useSelector } from 'react-redux';
import { func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import DialogHeader from './common/header';
import Info from './options/info';
import { getModuleById } from '../../../../selectors/module';
import getGeneralConditionsLink from '../../../../utils/getGeneralConditionsLink';

const DocumentDialog = ({ medialink, close, downloadLink, superModuleId }) => {
  const [t] = useTranslation();
  const currentModule = useSelector(state => getModuleById(state, medialink.moduleId));
  const downloadIsPreventedWithDemoProductLicense = currentModule.licenses.hasDemoProductLicenseForTeacher && !currentModule.licenses.hasDocumentLicense;

  return (
    <div className="info-dialog">
      <div className="ngdialog-overlay" />
      <div className="pbb-modal">
        <div className="pbb-modal__dialog pbb-modal--dialog-centered">
          <div className="pbb-modal__content">
            <DialogHeader close={close} title={medialink.name} icon={medialink.icon} />
            <div className="pbb-modal__body">
              <div className="pbb-modal__body-wrapper">
                <Info medialink={medialink} superModuleId={superModuleId} />
              </div>
            </div>
            <div className="pbb-modal__footer">
              {downloadLink &&
                (downloadIsPreventedWithDemoProductLicense ? (
                  <div className="noDownload-message" dangerouslySetInnerHTML={{ __html: t('mediaDialog.hasDemoLicense.message') }} />
                ) : (
                  <a href={downloadLink} target="_blank" rel="noopener noreferrer" className="pbb-btn pbb-btn--icon-left download-btn">
                    <i className="icon-bb-modal-download" />
                    {t('options.download')}
                  </a>
                ))}

              {!downloadLink && (
                <div
                  className="noDownload-message"
                  dangerouslySetInnerHTML={{ __html: t('mediaDialog.noMediaDownloadModal.message', { conditionsURL: getGeneralConditionsLink() }) }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentDialog.propTypes = {
  close: func.isRequired,
  medialink: shape({
    icon: string,
    name: string,
    moduleId: string.isRequired,
  }).isRequired,
  downloadLink: string,
  superModuleId: string,
};

DocumentDialog.defaultProps = {
  superModuleId: undefined,
  downloadLink: undefined,
};

export default DocumentDialog;
