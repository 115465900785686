import React, { useEffect, useState, useMemo } from 'react';
import { shape, string, oneOf } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { whitepageRendered } from '../../../../actions/player';
import WhitePage from './whitepage';
import PaperType from '../../../../enums/paperType';
import blank from './paper-types/blank.png';
import raster from './paper-types/raster.png';
import media from './paper-types/media.png';
import lines from './paper-types/lines.png';
import withDimensions from '../../../../hocs/with-dimensions';
import spinner from '../../../../../assets/images/spinner.svg';

const paperTypes = {
  blank,
  raster,
  media,
  lines,
};

async function loadJSON(url) {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    if (e.response.status === 403) {
      return [];
    }
    throw e;
  }
}

async function loadImage(src) {
  const image = new Image();
  return new Promise(resolve => {
    image.onload = () => {
      resolve(image);
    };

    image.crossOrigin = 'anonymous';
    image.src = src;
  });
}

function WhitePageContainer(props) {
  const [t] = useTranslation();
  const { whitepage } = props;
  const [drawings, setDrawings] = useState(null);
  const [annotations, setAnnotations] = useState(null);
  const [paper, setPaper] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const paperImagePromise = useMemo(() => loadImage(paperTypes[whitepage.paperType]), [whitepage.paperType]);
  const drawingDataPromise = useMemo(() => loadJSON(whitepage.url), [whitepage.url]);
  const annotationDataPromise = useMemo(() => loadJSON(whitepage.annotationsUrl), [whitepage.annotationsUrl]);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      const [paperImage, drawingData, annotationData] = await Promise.all([paperImagePromise, drawingDataPromise, annotationDataPromise]);
      setPaper(paperImage);
      setDrawings(drawingData);
      setAnnotations(annotationData);

      setIsLoading(false);
      dispatch(whitepageRendered());
    }

    loadData();
  }, [dispatch, paperImagePromise, drawingDataPromise, annotationDataPromise]);

  useEffect(() => {
    return () => {
      if (paper) paper.src = '';
    };
  }, [paper]);

  return isLoading ? (
    <div className="pbb-book-loader" data-testid="whitepage-spinner">
      <img src={spinner} alt={t('book.loading')} />
    </div>
  ) : (
    paper && <WhitePage whitepage={whitepage} initialDrawings={drawings} initialAnnotations={annotations} paper={paper} />
  );
}

WhitePageContainer.propTypes = {
  whitepage: shape({
    id: string.isRequired,
    title: string,
    url: string.isRequired,
    annotationsUrl: string.isRequired,
    paperType: oneOf(Object.values(PaperType)),
  }).isRequired,
};

export default withDimensions(WhitePageContainer);
