import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { func, number, arrayOf, string, bool, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getCurrentPageNumber } from '../../../../../../selectors/navigation';
import { getActiveNodes } from '../../../../../../selectors/toc';
import { gotoPreviousPage, gotoNextPage, setCurrentPage } from '../../../../../../actions/navigation';
import { openPrintModal } from '../../../../../../actions/dialog';
import PageInput from './pageInput';
import { PageEventContext } from '../../../../context/PageEventContext';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../../enums/analytics';

export const Footer = ({ currentPageNumber, dispatch, activeNodes }) => {
  const [t] = useTranslation();

  const { capturePageEvent } = useContext(PageEventContext);

  const handlePrevious = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_ARROWS);
    dispatch(gotoPreviousPage());
  };

  const handleSet = pageNumber => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_DIRECT);
    dispatch(setCurrentPage(pageNumber));
  };

  const handleNext = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_ARROWS);
    dispatch(gotoNextPage());
  };

  return (
    <div className="pbb-footer">
      <div className="pbb-mask" />

      <div className="pbb-footer__wrapper">
        <button
          className="footer__button -large"
          type="button"
          onClick={() => dispatch(openPrintModal(activeNodes))}
          data-testid="btn-print"
          title={t('drawer.toc.footer.buttons.print.tooltip')}
        >
          <i className="icon-print" />
        </button>

        <div className="pbb-page-jump">
          <div className="pbb-page__navigator pbb-page__navigator--left">
            <button type="button" onClick={() => handlePrevious()} data-testid="btn-previous" title={t('drawer.toc.footer.buttons.backward.tooltip')}>
              <i className="icon-bb-arrow-left" />
            </button>
          </div>
          <PageInput currentPageNumber={currentPageNumber} onSubmit={p => handleSet(p)} />
          <div className="pbb-page__navigator pbb-page__navigator--right">
            <button type="button" onClick={() => handleNext()} data-testid="btn-next" title={t('drawer.toc.footer.buttons.forward.tooltip')}>
              <i className="icon-bb-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentPageNumber: getCurrentPageNumber(state),
  activeNodes: getActiveNodes(state),
});

Footer.propTypes = {
  currentPageNumber: number,
  activeNodes: arrayOf(
    shape({
      prefix: string,
      title: string,
      displayPrefix: bool,
    }),
  ),
  dispatch: func.isRequired,
};

Footer.defaultProps = {
  currentPageNumber: 0,
  activeNodes: [],
};

export default connect(mapStateToProps)(Footer);
