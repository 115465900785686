import get from 'get-value';
import { getActiveNodesForMediaLinks } from './toc';

export const getSharedMedia = state => sharedMediaId => get(state, ['sharedMedia', 'byId', sharedMediaId]);

const getSharedMediaForCurrentPages = state => {
  const visibleTOCNodes = getActiveNodesForMediaLinks(state);

  const sharedMedia = visibleTOCNodes.reduce((acc, node) => {
    const chapterNodeId = node.id;

    const sharedMediaForNode = get(state, ['sharedMedia', 'byTocNodeId', chapterNodeId]);

    if (sharedMediaForNode) {
      const expandedMedia = sharedMediaForNode.map(getSharedMedia(state));

      const mediaForNode = expandedMedia.filter(ml => node.id === ml.node);
      acc.push(...mediaForNode);
    }

    return acc;
  }, []);

  return sharedMedia;
};

export const getSharedMediaForCurrentPagesGroupedByNode = state => {
  const result = getSharedMediaForCurrentPages(state).reduce((acc, media) => {
    const nodeId = media.node;

    if (acc[nodeId]) {
      acc[nodeId].push(media);
    } else {
      acc[nodeId] = [media];
    }

    return acc;
  }, {});

  return result;
};
